import { IMyWindow } from '@lab-photos/app/shared/interfaces/my-window.interface';
import { isString } from 'lodash/index';

/**
 * @description
 * Used to define the Google Analytics Auth
 * Based on the environments variable injection in the index file
 * @param {IMyWindow} window The window object containing possibly the "googleAnalyticsAuth" variable
 * @returns {string} Return the Google Analytics Auth if it exists or null otherwise
 */
export function googleAnalyticsAuthFactory(window: IMyWindow): string | null {
  const { googleAnalyticsAuth } = window;

  if (isString(googleAnalyticsAuth)) {
    return googleAnalyticsAuth;
  }

  return null;
}
