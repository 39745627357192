import { TranslationService } from '@lab-photos/app/shared/classes/translation-service.class';
import { TranslateService } from '@ngx-translate/core';

/**
 * @param {TranslateService} translateService from ngx-translate/core
 * @param {string} localeId local id
 * @returns {TranslationService} translation service
 */
// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
export function translationFactory(translateService: TranslateService, localeId: string): TranslationService {
  return new TranslationService(translateService, localeId);
}
