import { EStorageKeys } from '@lab-photos/app/shared/models/enums/storage-keys.enum';
import { AppAuthenticationService } from '@lab-photos/app/shared/services/user-services/app-authentication/app-authentication.service';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CookieService } from 'ngx-cookie';

@Injectable({ providedIn: 'root' })
export class AuthoritiesGuard implements CanActivate {
  public constructor(
    private readonly _cookieStorage: CookieService,
    private readonly _appAuthenticationService: AppAuthenticationService
  ) {}

  public canActivate(): boolean | Promise<boolean> {
    const token = this._cookieStorage.get(EStorageKeys.AUTH_TOKEN);

    if (token) {
      return true;
    }

    this._appAuthenticationService.navigateToLogin();

    return false;
  }
}
