import { IUser } from '@lab-photos/app/pages/user/shared/models/interfaces/user.interface';
import { EStorageKeys } from '@lab-photos/app/shared/models/enums/storage-keys.enum';
import { StorageService } from '@lab-photos/app/shared/services/storage-services/storage.service';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppAuthenticationService {
  public readonly currentUserSubject$: Subject<IUser> = new Subject<IUser>();

  public constructor(
    private readonly _cookieStorage: CookieService,
    private readonly _storageService: StorageService,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {}

  public updateUserData(data: Readonly<IUser>): void {
    this.currentUserSubject$.next(data);
  }

  public saveAuthenticationData(data: { sessionId: string | undefined; token: string | undefined; userId: string }): void {
    this._storageService.setItem(EStorageKeys.USER_DATA, JSON.stringify(data));
    this._storageService.setItem(EStorageKeys.USER_ID, data.userId);
    this._cookieStorage.put(EStorageKeys.AUTH_TOKEN, data.token);
    this._cookieStorage.put(EStorageKeys.SESSION_ID, data.sessionId);
  }

  public clearAuthenticationData(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const windowRef = this._document.defaultView as any;

    windowRef.Appcues?.reset();
    this._storageService.removeItem(EStorageKeys.USER_DATA);
    this._storageService.removeItem(EStorageKeys.USER_ID);
    this._cookieStorage.remove(EStorageKeys.AUTH_TOKEN);
    this._cookieStorage.remove(EStorageKeys.SESSION_ID);
  }

  public logout(): void {
    this.clearAuthenticationData();
    this._document.location.href = `/logout`;
  }

  public navigateToLogin(): void {
    const loginUrl = `/login?redirect=${encodeURIComponent(`${location.origin}`)}`;

    location.assign(loginUrl + encodeURIComponent(`&redirectUrl=${location.pathname + location.search}`));
  }

  public isAuthenticated(): boolean {
    return this._storageService.getItem(EStorageKeys.USER_DATA) !== null;
  }

  public getCurrentUser(): IUser {
    return JSON.parse(this._storageService.getItem(EStorageKeys.USER_DATA) || '{}');
  }

  public getCompanyId(): string {
    const userData = this.getCurrentUser();

    return userData.company?.id || '';
  }
}
