import { logRocketEnvironmentIdFactory } from '@lab-photos/app/shared/factories/logrocket-enviornment-id.factory';
import { LOGROCKET_ENVIRONMENT_ID } from '@lab-photos/app/shared/tokens/logrocket-enviroment-id.token';
import { WINDOW } from '@lab-photos/app/shared/tokens/window.token';
import { FactoryProvider } from '@angular/core';

export const LOGROCKET_ENVIRONMENT_ID_PROVIDER: FactoryProvider = {
  deps: [WINDOW],
  provide: LOGROCKET_ENVIRONMENT_ID,
  useFactory: logRocketEnvironmentIdFactory,
};
