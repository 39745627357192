import packageJson from '../../../../../../../../package.json';
import { IUser } from '@lab-photos/app/pages/user/shared/models/interfaces/user.interface';
import { LOGROCKET_ENVIRONMENT_ID } from '@lab-photos/app/shared/tokens/logrocket-enviroment-id.token';
import { Inject, Injectable } from '@angular/core';
import { QimaLoggerService } from '@qima/ngx-qima';
import { isEmpty } from 'lodash/index';
import LogRocket from 'logrocket';

interface IRequest {
  body?: string;
  credentials?: string;
  headers: { [key: string]: string | undefined };
  method: string;
  mode?: string;
  referrer?: string;
  reqId: string;
  url: string;
}

interface IResponse {
  body?: string;
  headers: { [key: string]: string | undefined };
  method: string;
  reqId: string;
  status?: number;
  url: string;
}

// Todo: replace the urls with aca
export const ENDPOINTS_WITH_PASSWORD: string[] = [
  'api/authenticate', // Login page
  'api/account/set-password', // Forgot password page
  'api/account/change-password', // Change password page
  'api/invitation/inspector/', // Inspectors invitation confirmation page
  'api/factories/invite', // Factories invitation confirmation page
  'api/invitation/auditor/', // Auditors invitation confirmation page
];

@Injectable({
  providedIn: 'root',
})
export class LogrocketInitService {
  public constructor(
    @Inject(LOGROCKET_ENVIRONMENT_ID) private readonly _logrocketEnvironmentId: Readonly<string>,
    private readonly _qimaLoggerService: QimaLoggerService
  ) {}

  public init(): void {
    if (!this._logrocketEnvironmentId || isEmpty(this._logrocketEnvironmentId.trim())) {
      this._qimaLoggerService.warn('LogRocket Environment Id not defined');
    } else {
      LogRocket.init(this._logrocketEnvironmentId, {
        console: {
          shouldAggregateConsoleErrors: true,
        },
        network: {
          requestSanitizer: this.requestSanitizerLogRocket,
          responseSanitizer: this.responseSanitizerLogRocket,
        },
      });
    }
  }

  public requestSanitizerLogRocket(request: IRequest): IRequest {
    if (ENDPOINTS_WITH_PASSWORD.some((path: string): boolean => request?.url?.toLowerCase().includes(path))) {
      request.body = undefined;
    }

    request.headers['Authorization'] = undefined;
    request.headers['Cookie'] = undefined;

    return request;
  }

  public responseSanitizerLogRocket(response: IResponse): IResponse {
    if (ENDPOINTS_WITH_PASSWORD.some((path: string): boolean => response.url.toLowerCase().includes(path))) {
      response.body = undefined;
    }

    response.headers['authorization'] = undefined;

    return response;
  }

  public identifyUserForLogRocket(userData: Readonly<IUser>): void {
    LogRocket.identify(userData.login ?? '', {
      name: userData.login,
      id: userData.id,
      email: userData.email,
      company: userData.company.name,
      companyId: userData.company.id,
    });
    LogRocket.track('application', { version: packageJson.version });
  }
}
