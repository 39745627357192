import { appcuesAccountIdFactory } from '@lab-photos/app/shared/factories/appcues-account-id.factory';
import { APPCUES_ACCOUNT_ID } from '@lab-photos/app/shared/tokens/appcues-account-id.token';
import { WINDOW } from '@lab-photos/app/shared/tokens/window.token';
import { FactoryProvider } from '@angular/core';

export const APPCUES_ACCOUNT_ID_PROVIDER: FactoryProvider = {
  deps: [WINDOW],
  provide: APPCUES_ACCOUNT_ID,
  useFactory: appcuesAccountIdFactory,
};
