import { ROUTECONFIG } from './app-routing';
import { OverlayModule } from '@lab-photos/app/shared/components/overlay/overlay.module';
import { httpLoaderFactory, localInitProvider } from '@lab-photos/app/shared/factories/i18n.factory';
import { translationFactory } from '@lab-photos/app/shared/factories/translation.factory';
import { AuthInterceptor } from '@lab-photos/app/shared/interceptors/auth.interceptor';
import { APPCUES_ACCOUNT_ID_PROVIDER } from '@lab-photos/app/shared/providers/appcues-account-id.provider';
import { BROWSER_WINDOW_PROVIDER } from '@lab-photos/app/shared/providers/browser-window.provider';
import { GOOGLE_ANALYTICS_AUTH_PROVIDER } from '@lab-photos/app/shared/providers/google-analytics-auth.provider';
import { GOOGLE_ANALYTICS_ID_PROVIDER } from '@lab-photos/app/shared/providers/google-analytics-id.provider';
import { GOOGLE_ANALYTICS_INIT_PROVIDER } from '@lab-photos/app/shared/providers/google-analytics-init.provider';
import { GOOGLE_ANALYTICS_PREVIEW_PROVIDER } from '@lab-photos/app/shared/providers/google-analytics-preview.provider';
import { LOGROCKET_ENVIRONMENT_ID_INIT_PROVIDER } from '@lab-photos/app/shared/providers/logrocket-environment-id-init.provider';
import { LOGROCKET_ENVIRONMENT_ID_PROVIDER } from '@lab-photos/app/shared/providers/logrocket-environment-id.provider';
import { SERVICE_WORKER_PROVIDER } from '@lab-photos/app/shared/providers/service-worker.provider';
import { SHOULD_SESSION_STORAGE_ENCRYPT_PROVIDER } from '@lab-photos/app/shared/providers/should-session-storage-encrypt.provider';
import { WINDOW_PROVIDER } from '@lab-photos/app/shared/providers/window.provider';
import { AppBrowserDetectService } from '@lab-photos/app/shared/services/browser-services/app-browser-detect/app-browser-detect.service';
import { HttpAuthInterceptor } from '@lab-photos/app/shared/services/http-services/http-auth-handler/http-auth-interceptor.service';
import { HTTP_INTERCEPTORS, HttpBackend, provideHttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, FactoryProvider, importProvidersFrom, LOCALE_ID, ValueProvider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  EQimaLoggerLevel,
  QIMA_ASSETS_PATH_TOKEN,
  QIMA_TRANSLATION_FACTORY_TOKEN,
  QimaDateModule,
  QimaIconModule,
  QimaLoggerModule,
  QimaModule,
  QimaSnackbarService,
  QimaTranslationModule,
} from '@qima/ngx-qima';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CookieModule } from 'ngx-cookie';

export const LAB_PHOTOS_CONFIG: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    QimaSnackbarService,
    HttpAuthInterceptor,
    WINDOW_PROVIDER,
    BROWSER_WINDOW_PROVIDER,
    SHOULD_SESSION_STORAGE_ENCRYPT_PROVIDER,
    SERVICE_WORKER_PROVIDER,
    GOOGLE_ANALYTICS_ID_PROVIDER,
    GOOGLE_ANALYTICS_AUTH_PROVIDER,
    GOOGLE_ANALYTICS_PREVIEW_PROVIDER,
    GOOGLE_ANALYTICS_INIT_PROVIDER,
    APPCUES_ACCOUNT_ID_PROVIDER,
    LOGROCKET_ENVIRONMENT_ID_PROVIDER,
    LOGROCKET_ENVIRONMENT_ID_INIT_PROVIDER,
    {
      provide: APP_INITIALIZER,
      useFactory: localInitProvider,
      deps: [TranslateService, AppBrowserDetectService],
      multi: true,
    },
    {
      provide: QIMA_ASSETS_PATH_TOKEN,
      useValue: 'ngx-qima/assets/',
    } as ValueProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: QIMA_TRANSLATION_FACTORY_TOKEN,
      useFactory: translationFactory,
      deps: [TranslateService, LOCALE_ID],
    } as FactoryProvider,

    provideRouter(ROUTECONFIG),
    importProvidersFrom(BrowserModule),
    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(QimaModule.forRoot()),
    importProvidersFrom(QimaDateModule),
    importProvidersFrom(QimaIconModule),
    importProvidersFrom(OverlayModule),
    importProvidersFrom(QimaTranslationModule.forRoot()),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpBackend],
        },
      })
    ),
    // Used by ngx-qima library
    importProvidersFrom(AngularSvgIconModule.forRoot()),
    importProvidersFrom(QimaTranslationModule.forRoot()),
    importProvidersFrom(CookieModule.forRoot()),
    importProvidersFrom(
      QimaLoggerModule.forRoot({
        loggerLevel: EQimaLoggerLevel.ERROR,
      })
    ),
    importProvidersFrom(
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: true,
        // Register the ServiceWorker as soon as the application is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      })
    ),
  ],
};
