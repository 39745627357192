import { EStorageKeys } from '@lab-photos/app/shared/models/enums/storage-keys.enum';
import { ESupportedLanguage } from '@lab-photos/app/shared/models/enums/supported-language.enum';
import { AppBrowserDetectService } from '@lab-photos/app/shared/services/browser-services/app-browser-detect/app-browser-detect.service';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localforage from 'localforage';

/**
 * @description
 * ⚠️ Use {@link HttpBackend} instead of {@link HttpClient} to avoid circular dependency due to interceptors catching the requests
 * @param {HttpBackend} httpBackend The http backend instance
 * @returns {TranslateHttpLoader} The loader
 */
export function httpLoaderFactory(httpBackend: Readonly<HttpBackend>): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(httpBackend));
}

/**
 * @description
 * Factory that will initialize the locale
 * @param {TranslateService} translate The locale service to initialize
 * @param {AppBrowserDetectService} browserService The browser service to get the supported language
 * @returns {() => Promise<void>} Function that initialize the locale service
 */
// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
export function localInitProvider(translate: Readonly<TranslateService>, browserService: Readonly<AppBrowserDetectService>): () => Promise<void> {
  return async (): Promise<void> => {
    const lang = await localforage.getItem(EStorageKeys.LOCALE_LANGUAGE);
    const language = (lang as string) || browserService.getSupportedBrowserLanguage() || ESupportedLanguage.ENGLISH;

    translate.addLangs([language]);
    translate.setDefaultLang(language);
    await translate.use(language).toPromise();
  };
}
