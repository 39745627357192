import { shouldSessionStorageEncryptFactory } from '@lab-photos/app/shared/factories/should-session-storage-encrypt.factory';
import { SHOULD_SESSION_STORAGE_ENCRYPT } from '@lab-photos/app/shared/tokens/should-session-storage-encrypt.token';
import { WINDOW } from '@lab-photos/app/shared/tokens/window.token';
import { FactoryProvider } from '@angular/core';

export const SHOULD_SESSION_STORAGE_ENCRYPT_PROVIDER: FactoryProvider = {
  deps: [WINDOW],
  provide: SHOULD_SESSION_STORAGE_ENCRYPT,
  useFactory: shouldSessionStorageEncryptFactory,
};
