import { OverlayComponent } from '@lab-photos/app/shared/components/overlay/overlay.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QimaSpinnerModule, QimaThreeSquaresLoaderModule } from '@qima/ngx-qima';

@NgModule({
  declarations: [OverlayComponent],
  exports: [OverlayComponent],
  imports: [CommonModule, QimaThreeSquaresLoaderModule, QimaSpinnerModule],
})
export class OverlayModule {}
