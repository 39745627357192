import { googleAnalyticsAuthFactory } from '@lab-photos/app/shared/factories/google-analytics/google-analytics-auth.factory';
import { GOOGLE_ANALYTICS_AUTH } from '@lab-photos/app/shared/tokens/google-analytics-auth.token';
import { WINDOW } from '@lab-photos/app/shared/tokens/window.token';
import { FactoryProvider } from '@angular/core';

export const GOOGLE_ANALYTICS_AUTH_PROVIDER: FactoryProvider = {
  deps: [WINDOW],
  useFactory: googleAnalyticsAuthFactory,
  provide: GOOGLE_ANALYTICS_AUTH,
};
