import { googleAnalyticsIdFactory } from '@lab-photos/app/shared/factories/google-analytics/google-analytics-id.factory';
import { GOOGLE_ANALYTICS_ID } from '@lab-photos/app/shared/tokens/google-analytics-id.token';
import { WINDOW } from '@lab-photos/app/shared/tokens/window.token';
import { FactoryProvider } from '@angular/core';

export const GOOGLE_ANALYTICS_ID_PROVIDER: FactoryProvider = {
  deps: [WINDOW],
  useFactory: googleAnalyticsIdFactory,
  provide: GOOGLE_ANALYTICS_ID,
};
