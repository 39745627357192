import { AuthoritiesGuard } from '@lab-photos/app/shared/guards/authorities/authorities.guard';
import { Routes } from '@angular/router';

export const ROUTECONFIG: Routes = [
  {
    path: 'login',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    loadComponent: () => import('./pages/login/login.component').then(m => m.LoginComponent),
  },
  {
    path: 'search-order',
    canActivate: [AuthoritiesGuard],
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    loadComponent: () => import('@lab-photos/app/pages/order/pages/search-order/search-order.component').then(m => m.SearchOrderComponent),
  },
  {
    path: 'forgot-password',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    loadComponent: () => import('@lab-photos/app/pages/login/forgot-password/forgot-password.component').then(m => m.ForgotPasswordComponent),
  },
  {
    path: 'order-detail/:orderId/:reportNo',
    canActivate: [AuthoritiesGuard],
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    loadComponent: () => import('@lab-photos/app/pages/order/pages/order-detail/order-detail.component').then(m => m.OrderDetailComponent),
  },
  {
    path: '',
    redirectTo: 'search-order',
    pathMatch: 'full',
  },
  {
    path: 'logout',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '**',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    loadComponent: () => import('@lab-photos/app/shared/components/not-found/not-found.component').then(m => m.NotFoundComponent),
  },
];
