export enum EStorageKeys {
  ADD_SUPPLIER = 'app-supplier',
  AUTH_TOKEN = 'authenticationToken',
  BOOK_DRAFT = 'book-draft.data',
  DRAFT_CHECKLIST = 'draft-checklist.data',
  ENTITY_TYPE = 'entityType',
  LOCALE_LANGUAGE = 'localeLanguage',
  ORDER_DETAIL_BACK = 'orderDetailBack',
  PRODUCT_CATEGORIES = 'productCategories',
  PRODUCT_TREE = 'ProductTree',
  PRODUCT_TYPE_WITH_CHECKLIST = 'productTypesWithChecklist',
  SESSION_ID = 'session-id',
  USER_DATA = 'userData',
  USER_ID = 'userId',
}
