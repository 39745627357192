import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterLink, RouterOutlet],
})
export class AppComponent {
  public title = 'lab-photos';

  public constructor(private readonly _translate: TranslateService) {
    this._translate.setDefaultLang('en');
    void this._translate.use('en');
  }
}
