import { ILanguage, LANGUAGES } from '@lab-photos/app/shared/constants/languages.constants';
import { Injectable } from '@angular/core';

enum EBrowserType {
  CHROME = 'Chrome',
  EDGE = 'Edge',
  FIREFOX = 'Firefox',
  IE = 'IE',
  OPERA = 'Opera',
  SAFARI = 'Safari',
}

@Injectable({
  providedIn: 'root',
})
export class AppBrowserDetectService {
  public getBrowserType(): EBrowserType {
    const { userAgent } = navigator;
    let browserType: EBrowserType = EBrowserType.CHROME;

    if (userAgent.indexOf('Opera') > -1) {
      browserType = EBrowserType.OPERA;
    }

    if (userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && browserType !== EBrowserType.OPERA) {
      browserType = EBrowserType.IE;
    }

    if (userAgent.indexOf('Windows NT 6.1; Trident/7.0;') > -1 && browserType !== EBrowserType.IE) {
      browserType = EBrowserType.EDGE;
    }

    if (userAgent.indexOf('Firefox') > -1) {
      browserType = EBrowserType.FIREFOX;
    }

    if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') == -1) {
      browserType = EBrowserType.SAFARI;
    }

    if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') == -1) {
      browserType = EBrowserType.CHROME;
    }

    return browserType;
  }

  public isIE(): boolean {
    return this.getBrowserType() === EBrowserType.IE;
  }

  public isEdge(): boolean {
    return this.getBrowserType() === EBrowserType.EDGE;
  }

  public isSafari(): boolean {
    return this.getBrowserType() === EBrowserType.SAFARI;
  }

  public isBrowserNotSupported(): boolean {
    return this.isIE() || this.isEdge() || this.isSafari();
  }

  public isMicrosoftBrowser(): boolean {
    return this.isIE() || this.isEdge();
  }

  public getSupportedBrowserLanguage(): string {
    // Check if the browser's default language is supported
    const currentLanguage = navigator.language;
    const supportedLanguage = this._getSupportedLanguage(currentLanguage);

    if (supportedLanguage) {
      return supportedLanguage.value;
    }

    // If the browser's default language is not supported
    // Find the supported language from browser language list
    const languageList = navigator.languages;
    const alternativeLanguage = languageList.find((item): boolean => {
      return !!this._getSupportedLanguage(item);
    });

    if (alternativeLanguage) {
      return alternativeLanguage.split('-')[0];
    }

    return '';
  }

  private _getSupportedLanguage(language: string): ILanguage | undefined {
    return LANGUAGES.find((item): boolean => language.includes(item.value));
  }
}
