import { IDataLayer } from '@lab-photos/app/shared/interfaces/data-layer.interface';
import { IMyWindow } from '@lab-photos/app/shared/interfaces/my-window.interface';
import { ScriptLoaderService } from '@lab-photos/app/shared/services/third-party-services/script-loader/script-loader.service';
import { AppAuthenticationService } from '@lab-photos/app/shared/services/user-services/app-authentication/app-authentication.service';
import { GOOGLE_ANALYTICS_ID } from '@lab-photos/app/shared/tokens/google-analytics-id.token';
import { WINDOW } from '@lab-photos/app/shared/tokens/window.token';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QimaLoggerService } from '@qima/ngx-qima';
import { catchError, from, Observable, tap, throwError } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService extends ScriptLoaderService {
  /**
   * @internal
   * @type {Renderer2}
   * @default undefined
   */
  public renderer: Renderer2 = this._rendererFactory.createRenderer(this._document.querySelector('html'), null);
  private readonly _gtmUrl = `https://www.googletagmanager.com/gtm.js`;
  private readonly _gtmNoScriptUrl = 'https://www.googletagmanager.com/ns.html';
  private readonly _gtmUrlParams = `?id=${this._googleAnalyticsId}`;

  public constructor(
    private readonly _router: Router,
    private readonly _qimaLoggerService: QimaLoggerService,
    private readonly _rendererFactory: RendererFactory2,
    private readonly _appAuthenticationService: AppAuthenticationService,
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(WINDOW) private readonly _window: IMyWindow,
    @Inject(GOOGLE_ANALYTICS_ID) private readonly _googleAnalyticsId: string
  ) {
    super();
    this._data = { src: '', id: 'googleAnalyticsScript' };
  }

  public init(): void {
    this._pushToDataLayer({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'gtm.start': new Date().getTime(),
      'event': 'gtm.js',
    });

    this._addGoogleAnalyticsToDom();
  }

  public sendEvent(data: Readonly<IDataLayer>): void {
    if (!this._isLoaded) {
      this._addGoogleAnalyticsToDom();
    }

    this._pushToDataLayer(data);
  }

  private _addGoogleAnalyticsToDom(): void {
    if (this._isLoaded || !this._googleAnalyticsId) {
      return;
    }

    this._watchGoogleAnalyticsScript$().pipe(untilDestroyed(this)).subscribe();
    this._appendIframe();
    this._isLoaded = true;
  }

  private _pushToDataLayer(data: Readonly<IDataLayer>): void {
    this._getDataLayer().push(data);
  }

  private _getDataLayer(): IDataLayer[] {
    this._window.dataLayer = this._window.dataLayer || [];

    return this._window.dataLayer;
  }

  private _appendIframe(): void {
    const googleAnalyticsIframe = this.renderer.createElement('iframe');
    const noscript = this.renderer.createElement('noscript');

    googleAnalyticsIframe.src = `${this._gtmNoScriptUrl}${this._gtmUrlParams}`;
    googleAnalyticsIframe.display = 'none';
    noscript.id = 'googleAnalyticsIframe';
    noscript.appendChild(googleAnalyticsIframe);
    this.renderer.insertBefore(this._document.body, noscript, this._document.body.firstChild);
  }

  private _watchGoogleAnalyticsScript$(): Observable<Event> {
    this._data.src = `${this._gtmUrl}${this._gtmUrlParams}`;
    this._data.async = true;

    return from(this._loadScript()).pipe(
      switchMap((): Observable<Event> => this._router.events),
      filter((event): boolean => event instanceof NavigationEnd),
      tap((event): void => {
        const navigationUrl = (event as NavigationEnd).url;

        // TODO: remove aca/book-confirmation/ when the new booking flow is live
        if (navigationUrl.startsWith('/book-confirmation') || navigationUrl.startsWith('/aca/book-confirmation/')) {
          const userData = this._appAuthenticationService.getCurrentUser();

          this.sendEvent({
            event: 'bookingSuccess',
            userId: userData.id,
            companyName: userData.company.name,
          });
        }
      }),
      catchError((err): Observable<never> => {
        this._qimaLoggerService.debug('unable to load Google Analytics', err);

        return throwError(err);
      })
    );
  }
}
