import { AppComponent } from '@lab-photos/app/app.component';
import { LAB_PHOTOS_CONFIG } from '@lab-photos/app/app.config';
import { ENVIRONMENT } from '@lab-photos/environments/environment';
import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';

declare let ENV_TYPE: 'dev' | 'preprod' | 'prod';

if (ENVIRONMENT[ENV_TYPE].production) {
  enableProdMode();
}

console.log('env_type:', ENV_TYPE);
bootstrapApplication(AppComponent, LAB_PHOTOS_CONFIG).catch((err): void => console.error(err));
