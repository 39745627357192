import { IMyWindow } from '@lab-photos/app/shared/interfaces/my-window.interface';
import { QimaOptionalType } from '@qima/ngx-qima';

/**
 * @description
 * Used to send user session data to appropriate project on appcues identified by environment id
 * @param {IMyWindow} window The window object containing possibly the "APPCUES_ID" variable
 * @returns {string} Return the environment ID if it exists or null otherwise
 */
export function appcuesAccountIdFactory(window: IMyWindow): QimaOptionalType<number> {
  return window.appcuesAccountId ?? null;
}
