import { WindowRef } from '@lab-photos/app/shared/classes/window-ref.class';
import { windowFactory } from '@lab-photos/app/shared/factories/window.factory';
import { WINDOW } from '@lab-photos/app/shared/tokens/window.token';
import { FactoryProvider, PLATFORM_ID } from '@angular/core';

export const WINDOW_PROVIDER: FactoryProvider = {
  deps: [WindowRef, PLATFORM_ID],
  provide: WINDOW,
  useFactory: windowFactory,
};
