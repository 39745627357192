import { ESupportedLanguage } from '@lab-photos/app/shared/models/enums/supported-language.enum';

export interface ILanguage {
  flagSrc: string;
  iso3: string;
  title: string;
  value: string;
}

export const LANGUAGES: ILanguage[] = [
  {
    title: 'TRANSLATE.ZH',
    value: ESupportedLanguage.CHINESE,
    iso3: 'CHN',
    flagSrc: '',
  },
  {
    title: 'TRANSLATE.EN',
    value: ESupportedLanguage.ENGLISH,
    iso3: 'USA',
    flagSrc: '',
  },
  {
    title: 'TRANSLATE.ES',
    value: ESupportedLanguage.SPANISH,
    iso3: 'ESP',
    flagSrc: '',
  },
  {
    title: 'TRANSLATE.PT',
    value: ESupportedLanguage.PORTUGUESE,
    iso3: 'BRA',
    flagSrc: '',
  },
];
